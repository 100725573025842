import React from 'react'
import { Card, Carousel, Col, Container, Row } from 'react-bootstrap'

export default function Herosection() {
    return (
        <div id='home'>
            <Carousel>
            <Carousel.Item>
                <img
                className="d-block w-100 heroImage"
                src="/images/plumb7.jpg"
                alt="First slide"
                />
                <Carousel.Caption>
                <h3>Quality Equipment</h3>
                <p>
                    Our equipment is of high standard, ensuring effectiveness and long-lasting durability
                </p>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
                <img
                className="d-block w-100 heroImage"
                src="/images/plumb8.jpg"
                alt="Second slide"
                />

                <Carousel.Caption>
                <h3>In-House Plumbing</h3>
                <p>
                    We carefully redesign the water and sewer systems in your house without affecting the structure and design of your home.
                </p>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
                <img
                className="d-block w-100 heroImage"
                src="/images/plumb3crop.jpg"
                alt="Third slide"
                />

                <Carousel.Caption>
                <h3>Factory or Industry Plumbing</h3>
                <p>
                Our industrial equipment is up to the standard, ensuring it can handle the workload of the factory.
                </p>
                </Carousel.Caption>
            </Carousel.Item>
            </Carousel>

            <Container className='mt-5 text-center'>
            <Row>
                <Col md={4} sm={12} >
                    <Card className="heroCard">
                    <Card.Body>
                        <i class="fas fa-money-bill-alt fa-2x heroIcons"></i>
                        <Card.Title>Value for Money</Card.Title>
                        <Card.Text>
Get quality, competitive, and effective services and equipment that will meet the needs of the work required.
                        </Card.Text>
                        
                    </Card.Body>
                    </Card>
                </Col>
                <Col md={4} sm={12} >
                    <Card className="heroCard">
                    <Card.Body>
                        <i class="fas fa-rainbow fa-2x heroIcons"></i>
                        <Card.Title>Quality Products</Card.Title>
                        <Card.Text>
We prioritize product quality in all of our projects to ensure long-lasting solutions for our clients
                        </Card.Text>
                        
                    </Card.Body>
                    </Card>
                </Col>
                <Col md={4} sm={12} >
                    <Card className="heroCard">
                    <Card.Body>
                        <i class="fab fa-resolving fa-2x heroIcons"></i>
                        <Card.Title>Reliable Solutions</Card.Title>
                        <Card.Text>
We don't just complete projects; we undertake projects designed to last and serve our clients well.
                        </Card.Text>
                        
                    </Card.Body>
                    </Card>
                </Col>
            </Row>
            </Container>
        </div>
    )
}
