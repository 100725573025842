import React from 'react'
import { Col, Card, Container, Image, Row, Button } from 'react-bootstrap'

export default function About() {
    return (
        <div id='about'>
            <Container className='mt-5'>
                <h2 className='text-center' style={{'text-decoration': 'underline', 'color': '#01014d'}}><strong>About Us</strong></h2>
            <Row className='mt-4'>
                <Col className='d-none d-md-block' md={6}>
                <Image src='/images/hero3.jpg' fluid />
                </Col>
                <Col md={6}>
                    <p> <strong>
                    We are a duly registered high-quality professional plumbing company offering services to both local and international clients in Uganda.
                        </strong></p>
                    <ul>
                        <p> <strong style={{'color': '#01014d'}} ><i class="fas fa-directions fa-2x"></i> 
                        Mission Statement: </strong> 
                        Through innovation and inspiration, Central Plumbing Ltd aims to continually improve the efficiency of project development from concept to completion to deliver a service that far exceeds our clients' expectations.
                        </p>

                        <p> <strong style={{'color': '#01014d'}} ><i class="fas fa-thumbs-up fa-2x"></i> 
                        Quality Statement: </strong> 
                        Our quality of work is a reflection of our business, and as such, we are proud to be part of an industry quality assurance system. All employees, from tender to construction, are dedicated to strictly adhering to our quality control procedures, as set out in our Quality Manual, to ensure a superior level of service deliverability, minimize risk, and build our reputation as a quality service partner.
                        </p>
                    </ul>
                    {/* <h6>We offer comprehensive services to our clients and 
                        operates both independently and together with other reputable well established building 
                        Contractors and developing firms in Kenya and beyond.</h6> */}
                </Col>
            </Row>
            </Container>

            <Container className="text-center mt-5 p-5 text-light note" fluid>
                <h4>Through client and market demand, Central Plumbing International Limited has evolved into 
                a highly experienced broad-based supplier of innovative hydraulic solutions.</h4>
                <p>Spanning a number of industries and market sectors, our knowledge and scope of work are tailored to deliver the best service.</p>
                <a href='#contacts'><Button className="btn btn-warning">CONTACT US NOW</Button></a>
            </Container>

            <Container className='text-center mt-5'>
                <h2 style={{'text-decoration': 'underline', 'color': '#01014d'}} >Our Philosophies and Policies</h2>
                <Row className='mt-3'>
                    <Col className='mt-2' md={6} sm={12}>
                    <Card className="policiesCards">
                    <Card.Body>
                    <i class="fas fa-hard-hat fa-2x" style={{'color': 'orangered'}}></i>
                        <Card.Title>Healthy and Safety:</Card.Title>
                        <Card.Text className='text-light'>
                        Safety is not just another box to be ticked. Before any work commences, our committed 
                        team ensure the work environment meets and maintains the highest level of safety, 
                        minimising injuries and project downtime.
                        </Card.Text>
                    </Card.Body>
                    </Card>
                    </Col>
                    <Col className='mt-2' md={6} sm={12}>
                    <Card className="policiesCards">
                    <Card.Body>
                    <i class="fas fa-tree fa-2x" style={{'color': 'orangered'}}></i>
                        <Card.Title>Environmental:</Card.Title>
                        <Card.Text className='text-light'>
                        At every level of our business we’re committed in our effort to minimise the environmental 
                        impact of our projects. From choice of material, waste disposal, efficiency of machinery and 
                        power, right down to the recycled paper of any brochure.
                        </Card.Text>
                    </Card.Body>
                    </Card>
                    </Col>
                </Row>

                <Row className='mt-3'>
                    <Col className='mt-2' md={6} sm={12}>
                    <Card className="policiesCards">
                    <Card.Body>
                    <i class="fas fa-pallet fa-2x" style={{'color': 'orangered'}}></i>
                        <Card.Title>Innovation:</Card.Title>
                        <Card.Text className='text-light'>
                        As an industry innovator we take pride in driving and developing the growth of the 
                        plumbing and hydraulic sector. Through strategic partnerships, alliances and relationships 
                        with like-minded organisations and government departments.
                        </Card.Text>
                    </Card.Body>
                    </Card>
                    </Col>
                    <Col className='mt-2' md={6} sm={12}>
                    <Card className="policiesCards">
                    <Card.Body>
                    <i class="fas fa-hands-helping fa-2x" style={{'color': 'orangered'}}></i>
                        <Card.Title>Rehabilitation Policy:</Card.Title>
                        <Card.Text className='text-light'>
                        In our business, our people are our most valuable asset. In the unlikely event of an injury, 
                        our Rehabilitation Plan ensures our staff receive the support they need to rest, recover and 
                        return to pre- injury duties.
                        </Card.Text>
                    </Card.Body>
                    </Card>
                    </Col>
                </Row>
            </Container>

        </div>
    )
}
