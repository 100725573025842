import React from 'react'
import About from './components/About'
import Contact from './components/Contact'
import Footer from './components/Footer'
import Herosection from './components/Herosection'
import Navba from './components/Navba'
import Services from './components/Services'
import Testimonials from './components/Testimonials'

export default function App() {
  return (
    <div>
      <Navba />
      <Herosection />
      <About />
      <Services />
      <Testimonials />
      <Contact />
      <Footer />
    </div>
  )
}
