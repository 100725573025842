import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

export default function Footer() {
    // Get the current year
    const currentYear = new Date().getFullYear();

    return (
        <div>
            <Container fluid className="text-white footer">
                <Container className='mt-5'>
                    <Row className='p-5'>
                        <Col>
                            <h3 style={{ 'textDecoration': 'underline' }}>Central Plumbing</h3>
                            <p>For quality plumbing solutions get in touch with us</p>
                            <p>+254731233924</p>
                            <p>info@centralplumbing.co.ke</p>
                            <i className="fab fa-facebook fa-2x p-2"></i>
                            <i className="fab fa-instagram fa-2x p-2"></i>
                            <i className="fab fa-linkedin-in fa-2x p-2"></i>
                        </Col>
                        <Col>
                            <h5>Our Services</h5>
                            <ul>
                                <li>maintenance and repair</li>
                                <li>sewer lines</li>
                                <li>water reticulation.</li>
                            </ul>
                        </Col>
                        <Col>
                            <h5>Important Links</h5>
                            <ul>
                                <li><a style={{ 'textDecoration': 'none', 'color': 'white' }} href='#home'>Home</a></li>
                                <li><a style={{ 'textDecoration': 'none', 'color': 'white' }} href='#about'>About</a></li>
                                <li><a style={{ 'textDecoration': 'none', 'color': 'white' }} href='#services'>Our Services</a></li>
                                <li><a style={{ 'textDecoration': 'none', 'color': 'white' }} href='#contacts'>Contacts</a></li>
                            </ul>
                        </Col>
                        <Col>
                            <h5>Some of our Clients</h5>
                            <ul>
                                <li>Sarit Center</li>
                                <li>Crown Plaza</li>
                                <li>I&M</li>
                                <li>Laxmanbhai</li>
                                <li>Safaricom</li>
                            </ul>
                        </Col>
                    </Row>
                </Container>

                <Container className='text-center p-2'>
                    <hr />
                    <h6>{`copyRight@${currentYear} All Rights Reserved`}</h6>
                    <p>Powered by <a href="https://aptech.co.ke" style={{ 'textDecoration': 'none', 'color': 'white' }}>Ap Tech Kenya</a></p>
                </Container>
            </Container>
        </div>
    )
}
