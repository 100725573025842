import React from 'react'
import { Card, Col, Container, Row } from 'react-bootstrap'

export default function Services() {
    return (
        <div>
            <Container fluid id="services">
            <Container className='mt-5 p-5'>
                <h2 className='text-center text-light' style={{'text-decoration': 'underline'}} >Our Services</h2>
                <p className='text-center text-light d-none d-md-block' >Through client and market demand, Central Plumbing International Limited has evolved into 
                a highly experienced broad-based supplier of innovative hydraulic solutions. 
                Spanning a number of industries and market sectors, our knowledge and scope of work 
                includes, but is not limited to:</p>
                <Row className='mt-4'>
                    <Col md={4} sm={12} className='md-3 mt-3'>
                        <Card>
                        <Card.Body>
                            <Row>
                                <Col md={2}><i class="fas fa-tools fa-2x" style={{'color': '#01014d'}} ></i></Col>
                                <Col md={10}><Card.Title>Maintenance and repair.</Card.Title></Col>
                            </Row>
                        </Card.Body>
                        </Card>
                    </Col>
                    {/* <Col md={3} sm={12} className='md-3 mt-3'>
                        <Card>
                        <Card.Body>
                            <Row>
                                <Col md={2}><i class="fas fa-blender fa-2x" style={{'color': '#01014d'}}></i></Col>
                                <Col md={10}><Card.Title>Thermostatic mixing valves.</Card.Title></Col>
                            </Row>
                        </Card.Body>
                        </Card>
                    </Col> */}
                    <Col md={4} sm={12} className='md-3 mt-3'>
                        <Card>
                        <Card.Body>
                            <Row>
                                <Col md={2}><i class="fas fa-university fa-2x" style={{'color': '#01014d'}}></i></Col>
                                <Col md={10}><Card.Title>High rise Developments.</Card.Title></Col>
                            </Row>
                        </Card.Body>
                        </Card>
                    </Col>
                    <Col md={4} sm={12} className='md-3 mt-3'>
                        <Card>
                        <Card.Body>
                            <Row>
                                <Col md={2}><i class="fas fa-tint fa-2x" style={{'color': '#01014d'}}></i></Col>
                                <Col md={10}><Card.Title>Water Reticulation.</Card.Title></Col>
                            </Row>
                        </Card.Body>
                        </Card>
                    </Col>
                    </Row>

                    <Row className='mt-4'>
                    {/* <Col md={3} sm={12} className='md-3 mt-3'>
                        <Card>
                        <Card.Body>
                            <Row>
                                <Col md={2}><i class="fas fa-toggle-on fa-2x" style={{'color': '#01014d'}}></i></Col>
                                <Col md={10}><Card.Title>MSR Switches.</Card.Title></Col>
                            </Row>
                        </Card.Body>
                        </Card>
                    </Col> */}
                    <Col md={4} sm={12} className='md-3 mt-3'>
                        <Card>
                        <Card.Body>
                            <Row>
                                <Col md={2}><i class="fas fa-igloo fa-2x" style={{'color': '#01014d'}}></i></Col>
                                <Col md={10}><Card.Title>Green Houses.</Card.Title></Col>
                            </Row>
                        </Card.Body>
                        </Card>
                    </Col>
                    <Col md={4} sm={12} className='md-3 mt-3'>
                        <Card>
                        <Card.Body>
                            <Row>
                                <Col md={2}><i class="fas fa-home fa-2x" style={{'color': '#01014d'}}></i></Col>
                                <Col md={10}><Card.Title>Housing.</Card.Title></Col>
                            </Row>
                        </Card.Body>
                        </Card>
                    </Col>
                    <Col md={4} sm={12} className='md-3 mt-3'>
                        <Card>
                        <Card.Body>
                            <Row>
                                <Col md={2}><i class="fas fa-school fa-2x" style={{'color': '#01014d'}}></i></Col>
                                <Col md={10}><Card.Title>Schools.</Card.Title></Col>
                            </Row>
                        </Card.Body>
                        </Card>
                    </Col>
                    </Row>

                    <Row className='mt-4'>
                    <Col md={3} sm={12} className='md-3 mt-3'>
                        <Card>
                        <Card.Body>
                            <Row>
                                <Col md={2}><i class="far fa-hospital fa-2x" style={{'color': '#01014d'}}></i></Col>
                                <Col md={10}><Card.Title>Hospitals.</Card.Title></Col>
                            </Row>
                        </Card.Body>
                        </Card>
                    </Col>
                    <Col md={3} sm={12} className='md-3 mt-3'>
                        <Card>
                        <Card.Body>
                            <Row>
                                <Col md={2}><i class="fas fa-hotel fa-2x" style={{'color': '#01014d'}}></i></Col>
                                <Col md={10}><Card.Title>Hotels.</Card.Title></Col>
                            </Row>
                        </Card.Body>
                        </Card>
                    </Col>
                    <Col md={3} sm={12} className='md-3 mt-3'>
                        <Card>
                        <Card.Body>
                            <Row>
                                <Col md={2}><i class="fas fa-flask fa-2x" style={{'color': '#01014d'}}></i></Col>
                                <Col md={10}><Card.Title>Laboratories.</Card.Title></Col>
                            </Row>
                        </Card.Body>
                        </Card>
                    </Col>
                    <Col md={3} sm={12} className='md-3 mt-3'>
                        <Card>
                        <Card.Body>
                            <Row>
                                <Col md={2}><i class="fas fa-grip-lines fa-2x" style={{'color': '#01014d'}}></i></Col>
                                <Col md={10}><Card.Title>Sewer Lines</Card.Title></Col>
                            </Row>
                        </Card.Body>
                        </Card>
                    </Col>
                    </Row>

            </Container>
            </Container>
        </div>
    )
}
