import React from 'react'
import { Navbar, Container, Nav} from 'react-bootstrap'

export default function Navba() {
    return (
        <div>
            <Navbar className='navbar' fixed="top" expand="md">
            <Container>
                <Navbar.Brand style={{'color': '#01014d'}} href="#home"> <img
                    alt=""
                    src="images/cpl.jpg"
                    width="70"
                    height="50"
                    className="d-inline-block align-top"
                    />{' '}</Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="ms-auto">
                    <Nav.Link style={{'color': '#01014d', 'font-weight': '600'}} href="#home">Home</Nav.Link>
                    <Nav.Link style={{'color': '#01014d', 'font-weight': '600'}} href="#about">About</Nav.Link>
                    <Nav.Link style={{'color': '#01014d', 'font-weight': '600'}} href="#services">Services</Nav.Link>
                    <Nav.Link style={{'color': '#01014d', 'font-weight': '600'}} href="#contacts">Contacts</Nav.Link>
                </Nav>
                </Navbar.Collapse>
            </Container>
            </Navbar>
        </div>
    )
}