import React from 'react'
import { Button, Col, Container, Form, Row } from 'react-bootstrap'

export default function Contact() {
    return (
        <div id='contacts'>
            <Container className="mt-5">

                <h2 className='mt-4 text-center' style={{'color': '#01014d', 'text-decoration': 'underline'}}>Contact Us</h2>
                <h6 className='text-center' >Get intouch with us and get your plumbing issue sorted.</h6> 

                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3988.8657403633115!2d36.749407972961045!3d-1.2520464355854652!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x182f19b2fb37aa03%3A0x22785e25fc993511!2sLoresho%20Ridge%2C%20Nairobi!5e0!3m2!1sen!2ske!4v1694767602945!5m2!1sen!2ske"
                    width="100%"
                    height="450"
                    style={{ border: 0 }}
                    allowFullScreen=""
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                ></iframe>

                <Row className='mt-5'>
                    <Col md={4} sm={12} className='contDetails'>
                        <div>
                            <Row>
                                <Col md={2}><i class="fas fa-map-marker-alt fa-2x"style={{'color': '#01014d'}}></i></Col>
                                <Col md={10}>
                                <h4>Location</h4>
                                <p>Loresho Ridge</p>
                                <p>Road Nairobi Kenya</p>
                                </Col>
                            </Row>
                        </div>
                        <div>
                            <Row>
                                <Col md={2}><i class="fas fa-phone-volume fa-2x"style={{'color': '#01014d'}}></i></Col>
                                <Col md={10}>
                                <h4>Phone</h4>
                                <p>+254731233924</p>
                                <p>+254722650777</p>
                                </Col>
                            </Row>
                        </div>
                        <div>
                            <Row>
                                <Col md={2}><i class="far fa-envelope fa-2x"style={{'color': '#01014d'}}></i></Col>
                                <Col md={10}>
                                <h4>Email</h4>
                                <p>info@centralplumbing.co.ke</p>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                    <Col md={8} sm={12}>
                        <Form.Group className="mb-3">
                            <Row>
                                <Col><Form.Control type="text" placeholder="Enter Name" /></Col>
                                <Col><Form.Control type="email" placeholder="Email" /></Col>
                            </Row>
                            <Form.Control className="mt-3" type="text" placeholder="Subject" />
                            <Form.Control className="mt-3" as="textarea" placeholder='Enter message' rows={5} />
                        
                            <Button className='btn btn-success mt-3'>Send It</Button>
                        </Form.Group>
                    </Col>
                </Row>
            </Container>
            
        </div>
    )
}
