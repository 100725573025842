import React from 'react'
import { Carousel, Col, Container, Row } from 'react-bootstrap'

export default function Testimonials() {
    return (
        <div>
            <Container className='mt-5'>
                <h2 className='text-center' style={{'text-decoration': 'underline', 'color': '#01014d'}}>Some of Our Clients</h2>

                <Row className='mt-4'>
                    <Col md={6} sm={12}>
                        <h2>Innovation and Value-adding</h2>
                        <p>Our passion to exceed at every phase of development drives us to seek out new and 
                            innovative solutions to go well beyond the expectations of our clients. Our commitment to 
                            value management guarantees the monitoring of all transactions from estimates through to 
                            sign-off. These include:</p>
                        <ul>
                            <li style={{'list-style-type':'none', 'color':'#01014d'}} > <i class="far fa-check-circle fa-1x"></i> Improving project safety</li>
                            <li style={{'list-style-type':'none', 'color':'#01014d'}} > <i class="far fa-check-circle fa-1x"></i> Saving time while reducing costs</li>
                            <li style={{'list-style-type':'none', 'color':'#01014d'}} > <i class="far fa-check-circle fa-1x"></i> Strategic problem solving</li>
                            <li style={{'list-style-type':'none', 'color':'#01014d'}} > <i class="far fa-check-circle fa-1x"></i> Increased quality and efficiency</li>
                            <li style={{'list-style-type':'none', 'color':'#01014d'}} > <i class="far fa-check-circle fa-1x"></i> Effective use of available resources</li>
                            <li style={{'list-style-type':'none', 'color':'#01014d'}} > <i class="far fa-check-circle fa-1x"></i> As built drawings</li>
                            <li style={{'list-style-type':'none', 'color':'#01014d'}} > <i class="far fa-check-circle fa-1x"></i> Operating & Maintenance manual</li>
                        </ul>
                        <p>It’s this innovation which has built our company and reputation over the years and is the 
                            driving force behind our ‘no problems, only solutions’ policy.</p>
                    </Col>

                    <Col md={6} sm={12}>
                    <Carousel>
                        <Carousel.Item>
                            <img
                            className="d-block w-100 heroImage"
                            src="/images/sarit.jpg"
                            alt="First slide"
                            />
                            <Carousel.Caption>
                            <h3>Sarit Center</h3>
    
                            </Carousel.Caption>
                        </Carousel.Item>
                        <Carousel.Item>
                            <img
                            className="d-block w-100 heroImage"
                            src="/images/i&m.jpeg"
                            alt="Second slide"
                            />

                            <Carousel.Caption>
                            <h3>I&M</h3>
                            </Carousel.Caption>
                        </Carousel.Item>
                        <Carousel.Item>
                            <img
                            className="d-block w-100 heroImage"
                            src="/images/saf.jpg"
                            alt="Third slide"
                            />

                            <Carousel.Caption>
                            <h3>Safaricom House</h3>
                            
                            </Carousel.Caption>
                        </Carousel.Item>
                        <Carousel.Item>
                            <img
                            className="d-block w-100 heroImage"
                            src="/images/lax.jpeg"
                            alt="Third slide"
                            />

                            <Carousel.Caption>
                            <h3>Laxmanbhai</h3>
                            
                            </Carousel.Caption>
                        </Carousel.Item>

                        <Carousel.Item>
                            <img
                            className="d-block w-100 heroImage"
                            src="/images/crownplaza.avif"
                            alt="Third slide"
                            />

                            <Carousel.Caption>
                            <h3>Crown Plaza</h3>
                            
                            </Carousel.Caption>
                        </Carousel.Item>

                        </Carousel>
                        
                    </Col>
                </Row>
            </Container>
        </div>
    )
}
